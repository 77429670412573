





































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import paystackStoreModule from "@/store/modules/paystack";
import { Business, VendorPlan } from "@/types";
// import { Role, User } from "@/types";

const { mapActions: paystackActions } = createNamespacedHelpers(
  "PAYSTACK_SUBSCRIPTION"
);

export default Vue.extend({
  name: "SubscribePlan",
  // props: {
  //   planId: {
  //     type: String,
  //     required: true,
  //   },
  // },
  data: () => ({
    showInitDialog: false,
    authUrl: "",
    loading: false,
    authorizationCode: "",
  }),
  computed: {
    // role(): Role {
    //   return this.$store.getters.role;
    // },
    vendorPlan(): VendorPlan {
      return JSON.parse(localStorage.getItem("plan") as string);
    },
  },
  methods: {
    ...paystackActions(["initPlanTransaction", "subscribeCustomer"]),
    async initSubsription() {
      this.loading = true;
      const roleId = localStorage.getItem("roleId") as string;
      const data = await this.$store.dispatch("getLoggedInUser");
      const vendorPlan: VendorPlan = data?.roles.find(
        (role) => role._id === roleId
      )?.business.vendorPlan;

      if (vendorPlan?.paystackAuthorizationCode) {
        this.authorizationCode = vendorPlan.paystackAuthorizationCode;
        this.subscribe();
        return;
      }
      this.initPlanTransaction({
        //email: (this.role.user as User).email,
        reference: this.vendorPlan._id,
        amount: 100,
      }).then((url: string) => {
        this.loading = false;
        if (url) {
          this.authUrl = url;
          this.showInitDialog = true;
          this.checkSubscription();
        }
      });
    },
    async checkSubscription() {
      const roleId = localStorage.getItem("roleId") as string;
      const data = await this.$store.dispatch("getLoggedInUser");
      const vendorPlan: VendorPlan = data?.roles.find(
        (role) => role._id === roleId
      )?.business.vendorPlan;

      if (vendorPlan?.paystackAuthorizationCode) {
        this.authorizationCode = vendorPlan.paystackAuthorizationCode;
        this.subscribe();
      } else {
        setTimeout(() => {
          this.checkSubscription();
        }, 2500);
      }
    },
    subscribe() {
      this.loading = true;
      this.subscribeCustomer({
        businessId:
          (this.vendorPlan.business as Business)._id ??
          this.vendorPlan.business,
        planId: this.vendorPlan.plan._id,
        authorizationCode: this.authorizationCode,
      }).then((subcription) => {
        this.loading = false;
        if (subcription) {
          this.$router.replace((this.$route.query.redirect as string) ?? "/");
        }
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("PAYSTACK_SUBSCRIPTION")) {
      this.$store.registerModule("PAYSTACK_SUBSCRIPTION", paystackStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("PAYSTACK_SUBSCRIPTION");
  },
});
